import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Media } from '../components/Media';
import breakPoints from '../helpers/breakPoints';
import { Layout } from '../components';
import { generateUrl } from '../helpers/cloudinary';
import logo from '../img/grant-murray-logo-no-text.svg';
import Footer from '../components/Footer';

const SimpleSlider = (items, title) => (
    <div>
        <Carousel dynamicHeight infiniteLoop useKeyboardArrows>
            {items.map((item) => (
                <div>
                    <img
                        style={{ objectFit: 'cover' }}
                        alt={title}
                        src={generateUrl(`${title.replace(/\s+/g, '-').toLowerCase()}/${item}`)}
                    />
                </div>
            ))}
        </Carousel>
    </div>
);

const FlexContainer = styled.div`
    padding-top: 2rem;
    display: flex;

    @media (max-width: ${breakPoints.tablet - 1}px) {
        padding-top: 0;
        flex-direction: column;
        margin: 0;
    }
`;

const ProjectTitle = styled.h1`
    font-size: 3rem;
    text-transform: lowercase;
    letter-spacing: 2px;
    margin-top: -1rem;
`;

const BodyContainer = styled.div`
    text-align: justify;
    margin-bottom: 1rem;
    > p {
        margin-bottom: 1rem;
    }
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    padding-right: 1rem;
    flex: 0 0 40%;
    max-width: 40%;

    @media (max-width: ${breakPoints.tablet - 1}px) {
        margin: 1rem;
        max-width: unset;
    }
`;

const ProjectContainer = styled.div`
    padding: 0 2rem;
    max-width: unset;
    @media (max-width: ${breakPoints.tablet - 1}px) {
        padding: 0;
    }
`;

const About = styled.p`
    margin-bottom: 1.2rem;
    font-weight: bold;
`;

const CarouselContainer = styled.div`
    max-width: 60%;
    flex: 0 0 60%;

    @media (max-width: ${breakPoints.tablet - 1}px) {
        max-width: unset;
    }
`;

const StyledMedia = styled(Media)`
    max-width: 100%;
`;

const Breadcrumbs = styled.div`
    display: flex;
    flex: 1;
    font-size: 2.3rem;
    font-weight: bold;
`;

const formatDate = (date) =>
    new Date(date).toLocaleDateString('en-gb', {
        month: 'short',
        year: 'numeric',
    });

export const ProjectPageTemplate = ({
    title,
    about,
    html,
    architect,
    client,
    cost,
    status,
    completionDate,
    awards,
    imageGallery,
    category,
    location,
    photography,
}) => (
    <ProjectContainer className="container">
        <FlexContainer>
            <Media lessThan="tablet">
                <CarouselContainer>{SimpleSlider(imageGallery, title)}</CarouselContainer>
                <DetailsContainer>
                    <ProjectTitle>{title}</ProjectTitle>
                    <About>{about}</About>
                    <BodyContainer dangerouslySetInnerHTML={{ __html: html }} />
                    <p>
                        <strong>architect:</strong> {architect}
                    </p>
                    {client.link ? (
                        <p>
                            <strong>client: </strong>
                            <a rel="noopener noreferrer" target="_blank" href={client.link}>
                                {client.name}
                            </a>
                        </p>
                    ) : (
                        <p>
                            <strong>client:</strong> {client.name}
                        </p>
                    )}
                    <p>
                        <strong>cost:</strong> {cost || 'Undisclosed'}
                    </p>
                    {awards && (
                        <>
                            <p>
                                <strong>awards: </strong>
                            </p>
                            <ul>
                                {awards.map((award) => (
                                    <li>{award}</li>
                                ))}
                            </ul>
                        </>
                    )}
                    {location && (
                        <p>
                            <strong>location:</strong> {location}
                        </p>
                    )}
                    {photography && (
                        <p>
                            <strong>photography: </strong>
                            {photography.link ? (
                                <a rel="noopener noreferrer" target="_blank" href={photography.link}>
                                    {photography.photographer}
                                </a>
                            ) : (
                                <span>{photography.photographer}</span>
                            )}
                        </p>
                    )}
                </DetailsContainer>
                <Breadcrumbs>
                    <Link
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '1rem',
                        }}
                        to="/"
                    >
                        <img alt="logo" style={{ maxWidth: '80px' }} src={logo} />
                    </Link>
                    <p style={{ fontSize: '1.9rem' }}>|</p>
                    <Link
                        style={{
                            letterSpacing: '8px',
                            textTransform: 'lowercase',
                            color: '#4a4a4a',
                            fontWeight: '100',
                            fontSize: '1.5rem',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        to={`/projects/${category.toLowerCase()}`}
                    >
                        {category}
                    </Link>
                </Breadcrumbs>
            </Media>
            <StyledMedia greaterThanOrEqual="tablet">
                <div style={{ display: 'flex' }}>
                    <CarouselContainer>
                        {SimpleSlider(imageGallery, title)}
                        <Breadcrumbs>
                            <Link
                                to="/"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img alt="logo" style={{ maxWidth: '110px' }} src={logo} />
                            </Link>
                            <p style={{ fontSize: '2.5rem' }}>|</p>
                            <Link
                                style={{
                                    letterSpacing: '8px',
                                    textTransform: 'lowercase',
                                    color: '#4a4a4a',
                                    fontWeight: '100',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                to={`/projects/${category.toLowerCase()}`}
                            >
                                {category}
                            </Link>
                        </Breadcrumbs>
                    </CarouselContainer>
                    <DetailsContainer>
                        <ProjectTitle>{title}</ProjectTitle>
                        <About>{about}</About>
                        <BodyContainer dangerouslySetInnerHTML={{ __html: html }} />
                        <p>
                            <strong>architect:</strong> {architect}
                        </p>
                        {client.link ? (
                            <p>
                                <strong>client: </strong>
                                <a rel="noopener noreferrer" target="_blank" href={client.link}>
                                    {client.name}
                                </a>
                            </p>
                        ) : (
                            <p>
                                <strong>client:</strong> {client.name}
                            </p>
                        )}
                        <p>
                            <strong>cost:</strong> {cost || 'Undisclosed'}
                        </p>
                        {status && (
                            <>
                                <p>
                                    <strong>status: </strong> {status}{' '}
                                    {status === 'Completed' && completionDate && `- ${formatDate(completionDate)}`}
                                </p>
                            </>
                        )}
                        {awards && (
                            <>
                                <p>
                                    <strong>awards: </strong>
                                </p>
                                <ul>
                                    {awards.map((award) => (
                                        <li>{award}</li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {location && (
                            <p>
                                <strong>location:</strong> {location}
                            </p>
                        )}
                        {photography && (
                            <p>
                                <strong>photography: </strong>
                                {photography.link ? (
                                    <a rel="noopener noreferrer" target="_blank" href={photography.link}>
                                        {photography.photographer}
                                    </a>
                                ) : (
                                    <span>{photography.photographer}</span>
                                )}
                            </p>
                        )}
                    </DetailsContainer>
                </div>
            </StyledMedia>
        </FlexContainer>
        <Footer style={{ margin: '25px 0' }} />
    </ProjectContainer>
);

ProjectPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    about: PropTypes.string.isRequired,
    html: PropTypes.string.isRequired,
    awards: PropTypes.array.isRequired,
    imageGallery: PropTypes.array.isRequired,
    client: PropTypes.shape({
        name: PropTypes.string.isRequired,
        link: PropTypes.string,
    }),
    photography: PropTypes.shape({
        photographer: PropTypes.string.isRequired,
        link: PropTypes.string,
    }),
    cost: PropTypes.string,
    status: PropTypes.string,
    completionDate: PropTypes.string,
    architect: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    location: PropTypes.string,
};

const ProjectPage = ({ data: { markdownRemark: project } }) => (
    <Layout>
        <ProjectPageTemplate {...project.frontmatter} html={project.html} />
    </Layout>
);

ProjectPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired,
                    about: PropTypes.string.isRequired,
                })
            ).isRequired,
        }).isRequired,
    }),
};

export default ProjectPage;

export const pageQuery = graphql`
    query ProjectById($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                about
                featuredImage
                imageGallery
                category
                location
                mapLocation
                category
                awards
                cost
                status
                completionDate
                architect
                photography {
                    photographer
                    link
                }
                client {
                    name
                    link
                }
            }
        }
    }
`;
